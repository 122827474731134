import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../store/hooks';
import { useEffect } from 'react';
import environment from '../config';

export default function PrivateRoute({ redirectTo, children }) {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      environment.IS_HIDE_POSTS_NAVIGATION &&
      location.pathname.includes('post')
    ) {
      navigate('/');
    }
  }, [environment.IS_HIDE_POSTS_NAVIGATION, location.pathname, navigate]);

  const isAuth = useAuth();

  return isAuth ? (
    <>{children}</>
  ) : (
    <Navigate to={redirectTo} state={{ from: location }} />
  );
}
