export const navigationsTypes = [
  {
    title: 'Page',
    id: 'page',
  },
  {
    title: 'Custom URL',
    id: 'custom_url',
  },
  {
    title: 'Category',
    id: 'category',
  },
];

export const initStateNavigations = {
  title: '',
  item_type: 'category',
  parent_id: null,
};

export const addCategoryConstants = {
  minLength: 2,
  maxLength: 30,
  categoriesLimit: 6,
};

export enum AspectOfCropImageEnum {
  COLLECTION = 1.8,
  DETAILS_PAGE = 3,
}

export const postFileFormats = {
  image: ['image/jpeg', 'image/png', 'image/gif'],
};

export const maxPostFileSizeToLoadMb = 10 * 1024 * 1024; // 10Mb
