import environment from '../../config';

export type listItem = {
  text: menuTitle;
  path: string;
  activeRoutes: string[];
  children?: Array<{ text: string; path: string; activeRoutes: string[] }>;
};

export enum menuTitle {
  DASHBOARD = 'Dashboard',
  POSTS = 'Posts',
  PAGES = 'Pages',
  USERS = 'Users',
  HOT_TECH = 'HotTech',
  SPOTLIGHT = 'Spotlight',
  ARTICLES = 'Articles',
}

export const menuList: listItem[] = environment.IS_HIDE_POSTS_NAVIGATION
  ? [
      { text: menuTitle.DASHBOARD, path: '/', activeRoutes: ['/'] },
      {
        text: menuTitle.PAGES,
        path: '/pages',
        activeRoutes: ['/pages'],
      },
      {
        text: menuTitle.USERS,
        path: '/users',
        activeRoutes: ['/users'],
      },
    ]
  : [
      { text: menuTitle.DASHBOARD, path: '/', activeRoutes: ['/'] },
      {
        text: menuTitle.POSTS,
        path: '/posts',
        activeRoutes: [
          '/posts',
          // '/posts/news',
          '/posts/hot-tech',
          '/posts/articles',
          '/posts/spotlight',
        ],
        children: [
          {
            text: menuTitle.HOT_TECH,
            path: '/posts/hot-tech',
            activeRoutes: ['/posts/hot-tech'],
          },
          {
            text: menuTitle.ARTICLES,
            path: '/posts/articles',
            activeRoutes: ['/posts/articles'],
          },
          {
            text: menuTitle.SPOTLIGHT,
            path: '/posts/spotlight',
            activeRoutes: ['/posts/spotlight'],
          },
        ],
      },
      {
        text: menuTitle.PAGES,
        path: '/pages',
        activeRoutes: ['/pages'],
      },
      {
        text: menuTitle.USERS,
        path: '/users',
        activeRoutes: ['/users'],
      },
    ];
