import React, { useEffect, useState } from 'react';
import { ICroppedAreaPercentage } from '../../interfaces/post';
import getCroppedImg from '../../Utils/getCroppedImg';

interface ICroppedImageViewProps {
  imageUrl: string;
  croppedAreaPercentage?: ICroppedAreaPercentage;
}

const CroppedImageView = ({
  imageUrl,
  croppedAreaPercentage = { x: 0, y: 33, width: 100, height: 100 },
}: ICroppedImageViewProps) => {
  const [croppedImage, setCroppedImage] = useState<string | null>(null);

  useEffect(() => {
    const createCroppedImg = async () => {
      try {
        const croppedImage = await getCroppedImg({
          imageSrc: imageUrl,
          percentageCrop: croppedAreaPercentage,
        });

        setCroppedImage(croppedImage);
      } catch (e) {
        console.error(e);
      }
    };

    createCroppedImg();
  }, [croppedAreaPercentage]);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        overflow: 'hidden',
      }}
    >
      {croppedImage && (
        <img
          src={croppedImage}
          srcSet={`${croppedImage} 1x, ${croppedImage} 2x`}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
          alt="Cropped"
          loading="lazy"
        />
      )}
    </div>
  );
};

export default CroppedImageView;
