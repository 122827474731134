import React, { FC, FormEvent, useEffect, useState } from 'react';

// Redux
import { useCreateUserMutation } from '../../../store/user/userApi';

// Components
import ModalWrapper from '../ModalWrapper/ModalWrapper';

// Icon
import EyeLineIcon from 'remixicon-react/EyeLineIcon';
import EyeOffLineIcon from 'remixicon-react/EyeOffLineIcon';

// MUI
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';

// Notification
import { showSuccessNotify } from '../../helpers/showSuccessNotify';
import { showErrorNotify } from '../../helpers/showErrorNotify';

//styles
import s from './styles.module.scss';
import { LoadingButton } from '@mui/lab';

// Types

interface IUserCreateModalProps {
  isOpen: boolean;
  onClose: () => void;

  handleGetAllUsers: () => void;
}

const initialState = {
  username: '',
  email: '',
  password: '',
  first_name: '',
  last_name: '',
};

const UserCreateModal: FC<IUserCreateModalProps> = ({
  isOpen,
  onClose,
  handleGetAllUsers,
}) => {
  const [formData, setFormData] = useState(initialState);
  const [showPassword, setShowPassword] = useState(false);

  // RTK QUERY - Create user
  const [createUserByIdRequest, createUserByIdData] = useCreateUserMutation();
  const { isLoading } = createUserByIdData;

  useEffect(() => {
    const { data, isSuccess, isError, error } = createUserByIdData;
    if (isSuccess) {
      showSuccessNotify(data.message);
      handleGetAllUsers();
      handleClose();
      setFormData(initialState);
    }

    showErrorNotify(isError, error);
  }, [createUserByIdData]);

  // Handlers
  const handleClose = () => {
    onClose();
    setFormData(initialState);
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    createUserByIdRequest(formData);
  };

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <ModalWrapper open={isOpen} onClose={handleClose} title="Add New User">
      <form className={s.form} onSubmit={e => handleSubmit(e)}>
        <Box>
          <Typography className={s.label}>Username</Typography>
          <TextField
            className={s.input}
            variant="outlined"
            type="text"
            name="username"
            value={formData.username}
            onChange={handleChange}
            fullWidth
          />
        </Box>
        <Box className={s.inputsWrapper}>
          <Box className={s.inputWrapper}>
            <Typography className={s.label}>Email</Typography>
            <TextField
              className={s.input}
              variant="outlined"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              fullWidth
            />
          </Box>

          <Box className={s.inputWrapper}>
            <Typography className={s.label}>Password</Typography>

            <TextField
              className={s.input}
              variant="outlined"
              type={showPassword ? 'text' : 'password'}
              name="password"
              value={formData.password}
              onChange={handleChange}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      sx={{ color: 'var(--neutral-black)' }}
                    >
                      {showPassword ? <EyeOffLineIcon /> : <EyeLineIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
        <Box className={s.inputsWrapper}>
          <Box className={s.inputWrapper}>
            <Typography className={s.label}>First name</Typography>
            <TextField
              className={s.input}
              variant="outlined"
              type="text"
              name="first_name"
              value={formData.first_name}
              onChange={handleChange}
              fullWidth
            />
          </Box>

          <Box className={s.inputWrapper}>
            <Typography className={s.label}>Last name</Typography>
            <TextField
              className={s.input}
              variant="outlined"
              type="text"
              name="last_name"
              value={formData.last_name}
              onChange={handleChange}
              fullWidth
            />
          </Box>
        </Box>
        <Box className={s.buttonsWrapper}>
          <Button className={s.cancelButton} onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            startIcon={isLoading && <CircularProgress size={20} />}
            loadingPosition={isLoading ? 'start' : undefined}
            className={s.createButton}
            variant="contained"
            color="primary"
            fullWidth
            disableElevation
            disabled={isLoading}
          >
            Add new
          </LoadingButton>
        </Box>
      </form>
    </ModalWrapper>
  );
};

export default UserCreateModal;
