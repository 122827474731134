// React
import React, { FormEvent, useEffect, useState, FC } from 'react';

// Mui
import {
  TextField,
  Typography,
  Box,
  CircularProgress,
  InputAdornment,
  IconButton,
  Button,
} from '@mui/material';
import ModalWrapper from '../ModalWrapper/ModalWrapper';

// Icon
import EyeLineIcon from 'remixicon-react/EyeLineIcon';
import EyeOffLineIcon from 'remixicon-react/EyeOffLineIcon';

// Styles
import s from './styles.module.scss';

//redux
import {
  useUpdatePasswordMutation,
} from '../../../store/user/userApi';
import { useAppDispatch } from '../../../store/hooks';
import { setCurrentUser } from '../../../store/user/userSlice';

// Notification
import { showErrorNotify } from '../../helpers/showErrorNotify';
import { showSuccessNotify } from '../../helpers/showSuccessNotify';

interface IUserUpdatePasswordModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const UpdatePasswordModal: FC<IUserUpdatePasswordModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
  });
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);

  const dispatch = useAppDispatch();

  // RTK queries - update password
  const [
    updatePasswordRequest,
    {
      data: dataUpdatePassword,
      isSuccess: isSuccessUpdatePassword,
      isError: isErrorUpdatePassword,
      error: errorUpdatePassword,
      isLoading: isLoadingUpdatePassword,
    },
  ] = useUpdatePasswordMutation();

  useEffect(() => {
    if (dataUpdatePassword && isSuccessUpdatePassword) {
      showSuccessNotify(dataUpdatePassword.message);
      dispatch(setCurrentUser(dataUpdatePassword.data.user));
      setFormData({
        password: '',
        confirmPassword: '',
      });
      onClose();
    }

    showErrorNotify(isErrorUpdatePassword, errorUpdatePassword);
  }, [
    dataUpdatePassword,
    isSuccessUpdatePassword,
    errorUpdatePassword,
    isErrorUpdatePassword,
  ]);

  // Handlers
  const handleClose = () => {
    onClose();
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    updatePasswordRequest({ ...formData });
  };

  const handleClickShowPassword = () => setIsShowPassword(show => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () =>
    setIsShowConfirmPassword(show => !show);

  const handleMouseDownConfirmPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <ModalWrapper open={isOpen} onClose={handleClose} title="Reset Password">
      <form className={s.form} onSubmit={e => handleSubmit(e)}>

        <Box sx={{ marginBottom: '30px' }}>
          <Typography className={s.label}>New Password</Typography>

          <TextField
            className={s.input}
            variant="outlined"
            type={isShowPassword ? 'text' : 'password'}
            name="password"
            value={formData.password}
            onChange={handleChange}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    sx={{ color: 'var(--neutral-black)' }}
                  >
                    {isShowPassword ? <EyeOffLineIcon /> : <EyeLineIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box>
          <Typography className={s.label}>Confirm Password</Typography>

          <TextField
            className={s.input}
            variant="outlined"
            type={isShowConfirmPassword ? 'text' : 'password'}
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownConfirmPassword}
                    edge="end"
                    sx={{ color: 'var(--neutral-black)' }}
                  >
                    {isShowConfirmPassword ? (
                      <EyeOffLineIcon />
                    ) : (
                      <EyeLineIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Box className={s.buttonsWrapper}>
          <Button
            className={s.cancelButton}
            variant="outlined"
            color="primary"
            fullWidth
            disableElevation
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className={s.submitButton}
            variant="contained"
            color="primary"
            fullWidth
            disableElevation
            disabled={
              isLoadingUpdatePassword ||
              !formData.password ||
              !formData.confirmPassword
            }
          >
            {isLoadingUpdatePassword ? <CircularProgress size={20} /> :  "Update Password"}
          </Button>
        </Box>
      </form>
    </ModalWrapper>
  );
};

export default UpdatePasswordModal;
