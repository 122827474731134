import { ICroppedAreaPercentage } from '../interfaces/post';

const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', error => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues
    image.src = url;
  });

export default async function getCroppedImg({
  imageSrc,
  percentageCrop,
}: {
  imageSrc: string;
  percentageCrop: ICroppedAreaPercentage;
}): Promise<string> {
  const image = (await createImage(imageSrc)) as HTMLImageElement;
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('Could not get canvas context');
  }

  const pixelCrop = {
    x: (percentageCrop.x / 100) * image.width,
    y: (percentageCrop.y / 100) * image.height,
    width: (percentageCrop.width / 100) * image.width,
    height: (percentageCrop.height / 100) * image.height,
  };

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // Set each dimension to double the largest dimension to allow for a safe area
  canvas.width = safeArea;
  canvas.height = safeArea;

  // Translate canvas context to the center of the image for rotation
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // Draw rotated image and store data
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  );
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // Set canvas width to the final desired crop size - this will clear the existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // Paste the rotated image with correct offsets for x, y crop values
  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
  );

  // Return the cropped image as a Blob URL
  return new Promise<string>(resolve => {
    canvas.toBlob(file => {
      if (file) {
        resolve(URL.createObjectURL(file));
      } else {
        throw new Error('Failed to create Blob from canvas');
      }
    }, 'image/jpeg');
  });
}
