import React, { FC, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import './QuillEditor.css';
import 'react-quill/dist/quill.snow.css';

interface IQuillEditor {
  value: string;
  setFieldValue?: (val: string) => void;
  placeholder?: string;
  readOnly?: boolean;
  toolbarHidden?: boolean;
}

export const QuillEditor: FC<IQuillEditor> = ({
  value,
  setFieldValue,
  placeholder,
  readOnly,
  toolbarHidden,
}) => {
  const editorRef = useRef<ReactQuill | null>(null);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    const editor = editorRef.current?.getEditor();
    if (!editor) return;

    if (e.key === 'Tab') {
      e.preventDefault();

      const currentPosition = editor.getSelection()?.index;
      if (currentPosition !== undefined) {
        const spaces = ' '.repeat(4);
        editor.insertText(currentPosition, spaces);
        editor.setSelection(currentPosition + 4, 0);
      }
    }
  };

  const modules = {
    toolbar:
      readOnly || toolbarHidden
        ? false
        : [
            [{ header: '1' }, { header: '2' }, { font: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ['link', 'image', 'video'],
            ['code-block'],
            [{ indent: '-1' }, { indent: '+1' }],
            ['clean'],
          ],
    clipboard: {
      matchVisual: false,
    },
    history: {
      delay: 1000,
      userOnly: true,
    },
  };

  const preprocessValue = (html: string) => {
    return html.replace(/ {2,}/g, match => match.replace(/ /g, '&nbsp;'));
  };

  useEffect(() => {
    const handleDrop = (e: DragEvent) => {
      if (
        editorRef.current &&
        editorRef.current.getEditor().root &&
        !editorRef.current.getEditor().root.contains(e.target as Node)
      ) {
        e.preventDefault();
        e.stopPropagation();
        console.log('Drop event prevented outside the editor');
      }
    };

    const handleDragOver = (e: DragEvent) => {
      if (
        editorRef.current &&
        editorRef.current.getEditor().root &&
        !editorRef.current.getEditor().root.contains(e.target as Node)
      ) {
        e.preventDefault();
        e.stopPropagation();
        console.log('DragOver event prevented outside the editor');
      }
    };

    document.addEventListener('drop', handleDrop);
    document.addEventListener('dragover', handleDragOver);

    return () => {
      document.removeEventListener('drop', handleDrop);
      document.removeEventListener('dragover', handleDragOver);
    };
  }, []);

  return (
    <ReactQuill
      ref={editorRef}
      value={preprocessValue(value)}
      readOnly={readOnly}
      style={{ height: 300 }}
      placeholder={placeholder}
      onChange={newValue => {
        if (setFieldValue) {
          setFieldValue(newValue);
        }
      }}
      modules={modules}
      onKeyDown={handleKeyDown}
    />
  );
};

export default QuillEditor;
